<template>
  <div>
    <div class='flex'>
      <input class='w-full min-w-0 font-bold text-primary outline-none'
        readonly
        ref='input'
        :value='value'>
      <div class='flex text-gray-700 cursor-pointer select-none'
        @click='copy'>
        <i class='icon'>filter_none</i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    },

    methods: {
      copy () {
        this.$refs.input.select()
        document.execCommand('copy')
      }
    }
  }
</script>
