<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='sor m-auto'>
        <skeleton-loader-component :loading='!esim || !manualInstructions'>
          <template v-if='esim && manualInstructions'>
            <div class='flex mt-8 text-gray-700'>
              <router-link class='flex'
                :to='{ name: "esim", params: { id: esim.id } }'>
                <i class='icon'>arrow_back</i>
              </router-link>
              <div class='ml-16 text-lg font-bold'>{{ $t('pages.esim-manual.5593d957') }}</div>
            </div>
            <div class='flex mt-24 text-center shadow-sm cursor-pointer'>
              <div class='apa flex justify-center py-16 px-24 w-full rounded-l transition duration-200'
                :class='{ "active": tab === "ios" }'
                @click='tab = "ios"'>
                <apple-svg class='lea h-24 fill-current'/>
                <div class='ml-16'>{{ $t('common.ios') }}</div>
              </div>
              <div class='apa flex justify-center py-16 px-24 w-full rounded-r transition duration-200'
                :class='{ "active": tab === "android" }'
                @click='tab = "android"'>
                <android-svg class='lea h-24 fill-current'/>
                <div class='ml-16'>{{ $t('common.android') }}</div>
              </div>
            </div>
            <header-element class='mt-24'>{{ esim.order.item.data.operator }} : {{ esim.order.item.data.countries.length === 1 ? esim.order.item.data.countries[0].name : esim.order.item.data.region.name }}</header-element>
            <template v-if='tab === "ios"'>
              <card-element class='mt-24'>
                <div>{{ $t('pages.esim-manual.761b0c67') }}</div>
                <content-copy-component class='mt-8'
                  :value='esim.manuals[0]'/>
              </card-element>
              <card-element class='mt-24'>
                <div>{{ $t('pages.esim-manual.d1e02038') }}</div>
                <content-copy-component class='mt-8'
                  :value='esim.manuals[1]'/>
              </card-element>
            </template>
            <template v-if='tab === "android"'>
              <card-element class='mt-24'>
                <div>{{ $t('pages.esim-manual.acfe4640') }}</div>
                <content-copy-component class='mt-8'
                  :value='esim.qrCodeString.slice(4)'/>
              </card-element>
            </template>
            <card-element class='mt-24'>
              <div class='flex'>
                <div>{{ $t('pages.esim-manual.cd7c546a') }}</div>
                <div class='ml-8'>{{ esim.serialNumber }}</div>
              </div>
              <div class='flex mt-8'>
                <div>{{ $t('pages.esim-manual.7468fb0f') }}</div>
                <div class='ml-8'>{{ esim.order.item.data.name }}</div>
              </div>
              <div class='flex mt-8'>
                <div>{{ $t('pages.esim-manual.fb5c10f7') }}</div>
                <div class='ml-8'>{{ esim.order.item.data.countries.length === 1 ? esim.order.item.data.countries[0].name : esim.order.item.data.region.name }}</div>
              </div>
            </card-element>
            <header-element class='mt-24'>{{ $t('pages.esim-manual.d65415d6') }}</header-element>
            <card-element class='mt-24'>
              <div v-html='manualInstructions[0]'></div>
            </card-element>
          </template>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import Android from '@/assets/graphics/android.svg'
  import Apple from '@/assets/graphics/apple.svg'
  import BottomNavigation from '@/components/bottom-navigation'
  import ContentCopy from '@/components/content-copy'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import store from '@/store'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'content-copy-component': ContentCopy,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation,
      'android-svg': Android,
      'apple-svg': Apple
    },

    props: {
      id: String
    },

    data: () => ({
      tab: 'ios',
      esim: null
    }),

    computed: {
      instructions () {
        return this.$store.state.settings.instructions
      },

      manualInstructions () {
        if (!this.instructions) return null

        return this.instructions.manual
          .find(instruction => instruction.platform === this.tab)
          .instructions
      }
    },

    mounted () {
      this.fetchEsim()
    },

    methods: {
      async fetchEsim () {
        let id = this.id
        this.esim = await this.$store.dispatch('me/getEsim', { id })
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .sor {
    max-width: 480px;
  }

  .apa {
    @apply bg-white;

    &:hover {
      @apply
        text-white
        bg-primary-dark;
    }

    &.active {
      @apply
        text-white
        bg-primary;
    }
  }

  .lea {
    @apply text-gray-700;

    .apa:hover &,
    .apa.active & {
      @apply text-white;
    }
  }
</style>
